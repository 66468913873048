import React from 'react'

const Button = (props) => {
  const { label, onClick } = props

  return (
    <>
      {props?.disabled && (
        <button className="button_disabled textElement" onClick={onClick}>
          {label}
        </button>
      )}
      {!props?.disabled && (
        <button className="button textElement" onClick={onClick}>
          {label}
        </button>
      )}
    </>
  )
}

export default Button
